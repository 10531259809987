import React, { useState } from 'react'
import {Form, Button, Container, FormGroup, FormLabel, FormControl} from 'react-bootstrap'
import { NavLink } from 'react-bootstrap';
 
import './s_dmt.css';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
 
export const S_dmt=()=>(
 
 
<div>
 
<Header/>
   <div className='modalBackgroundDMT'> 
   <div className='modalmContainerDMT'> 
   <div className='titleCloseBtnDMT'>  
   
     
        <h1 className="text-DMT">Суруди Донишгоҳи миллии Тоҷикистон</h1>  
   </div>
  <Container className='ContDMT'>
 <p><b>Шеъри Муҳаммад Ғоиб  </b></p>
 <p> <b>Оҳанги Амирбеки Мусо</b>  </p>
 <p>--------------------------------</p>
 <p> Пайки рӯзи нек дорад субҳгоҳи маърифат, </p>
 <p>Мебарад  бар кӯи  мақсад  шоҳроҳи маърифат. </p>
 <p>Барнахезад  аз  қадамҷои  бузургонаш  ғубор, </p>
 <p>То абад рӯяд  аз ин  гулшан  гиёҳи маърифат. </p>
 <p>Мешавад  пайдо нишони  аввалу анҷоми нек, </p>
 <p>Дар ҳамин  даргоҳи илму зодгоҳи маърифат. </p>
 <p>--------------------------------</p>             
 <p>Раҳбалад дар роҳи дури ҳикмат аст,</p>
 <p>Парчами фатҳу ливои нусрат аст.</p>
 <p>Давлати иқболи соҳибдавлат  аст,</p>
 <p>Шаҳсутуни кохи илми  миллат аст.</p>
 <p>--------------------------------</p>
 <p>Бахти андак  нест, дорад номи миллатро  ба худ,</p>
 <p>Ин макони олимон, ин боргоҳи маърифат.</p>
 <p> Дидагони ҳар зиёӣ  меҳру моҳи кишвар аст,</p>
 <p>Партави  ҷовид  бошад  дар нигоҳи  маърифат.</p>
 <p>Умрҳо  дарси  ватандорӣ дар ин ҷо бигзаранд,</p>
 <p>Рӯи мағлубӣ намебинад сипоҳи маърифат.</p>
 <p>--------------------------------</p>        
 <p>Раҳбалад  дар роҳи  дури ҳикмат аст,</p>
 <p>Парчами фатҳу ливои нусрат аст.</p>
 <p>Давлати иқболи соҳибдавлат  аст,</p>
 <p>Шаҳсутуни кохи илми миллат аст.</p>
     

  </Container>
    
   
   </div> 
   </div>
<Footer/>
   </div>
    )
 

 