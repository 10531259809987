//import logo from './logo.svg';
import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Login} from './Pages/Login';
import {Register} from './Pages/Register';
import {Update} from './Pages/Update';
import {Asosi} from './Pages/Asosi';
import {Oidkafedra} from './Pages/Oidkafedra';
import {S_dmt} from './Pages/S_dmt';
import {Malumot_5} from './Pages/Malumot_5';
import {Malumot_1} from './Pages/Malumot_1';
import {Malumot_2} from './Pages/Malumot_2';
import {Malumot_3} from './Pages/Malumot_3';
import {Malumot_4} from './Pages/Malumot_4';
import {Malumot_6} from './Pages/Malumot_6';
import {Malumot_7} from './Pages/Malumot_7';
import {Malumot_8} from './Pages/Malumot_8';
import {Malumot_9} from './Pages/Malumot_9';
import {Malumot_10} from './Pages/Malumot_10';
import {Mail} from './Pages/Mail';

import {M_mili} from './Pages/M_mili';
import {Mavodho} from './Pages/Mavodho';
import {Test} from './Pages/Test';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollButton from './Components/ScrollButton';
import {useState, useEffect} from 'react';
import {K_pr} from './Pages/K_pr';


function App() {
  
   const [loading, setLoading] =useState(false)
 

  return (
   <div className='App'>
  
    {
    <BrowserRouter>
    <ScrollButton />
    <Routes>
      <Route exact path="/" element={<Asosi/>} />
      <Route path="/oidkafedra" element={<Oidkafedra/>} />
          <Route path="/Malumot_5" element={<Malumot_5/>} />
      <Route path="/Malumot_1" element={<Malumot_1/>} />
      <Route path="/Malumot_2" element={<Malumot_2/>} />
      <Route path="/Malumot_3" element={<Malumot_3/>} />
      <Route path="/Malumot_4" element={<Malumot_4/>} />
      <Route path="/Malumot_6" element={<Malumot_6/>} />
      <Route path="/Malumot_7" element={<Malumot_7/>} />
      <Route path="/Malumot_8" element={<Malumot_8/>} />
      <Route path="/Malumot_9" element={<Malumot_9/>} />
      <Route path="/Malumot_10" element={<Malumot_10/>} />
      <Route path="/mavodho" element={<Mavodho/>} />   
      <Route path="/test" element={<Test/>} />  
      <Route path="/Login" element={<Login/>} />  
      <Route path="/Register" element={<Register/>} />  
      <Route path="/Update" element={<Update/>} />  
      <Route path="/Mail" element={<Mail/>} />  
      <Route path="/S_dmt" element={<S_dmt/>} />  
      <Route path="/M_mili" element={<M_mili/>} />  
      <Route path="/K_pr" element={<K_pr/>} /> 
      </Routes>
    
    
    
    </BrowserRouter>
    
}
 </div>
  );
}

export default App;
