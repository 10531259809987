import React from "react";
import './omuzgoron.css';
import mudir from './../img/mudir.png';
import NI from './../img/NI.png';
import AH from './../img/AH.png';
import OK from './../img/OK.png';
import SHZ from './../img/SHZ.png';
import PS from './../img/PS.png';
import KN from './../img/KN.png';
import MS from './../img/MS.png';
import GN from './../img/GN.png';
import KKn from './../img/KKn.png';
import Footer from './Footer';
import  {useState, useEffect} from 'react';
import Header from './Header';
import axios from 'axios';
 

 
 
    

function Omuzgoron() {
       

      return(


 

 

<div className="omuzgor">
 
 
  

 
     <h1 id="omuzgoron">Маълумот оид ба омӯзгорони кафедра</h1> 
<div className="omuz_div">
<div className="omuz_grid">
            <div className="g11">
                      <a    className="all_a"  href="/malumot_1"> 
                  
                 
                
                  
            <img 
            src={NI}
           className="gimg"
           alt="Нуров"
            />

                  
                  <div className="gname">Нуров Исҳоқбой</div>
                  <div className="gname">Ҷумаевич </div>
<div className="gstatus">профессор</div>
<div className="gabout">д..и.ф.-м. </div>
 
 



</a>   </div>
<div className="g12"  >  <a href="/malumot_2" className="all_a"> 
           
 <img 
            src={KKn}
           className="gimg"
           alt="Қурбонов"
            />

                  
                  <div className="gname">Қурбонов Кенҷа</div>
                  <div className="gname">Юлчиевич </div>
<div className="gstatus">дотсент</div>
<div className="gabout">н.и.и.  </div>
 


 
</a>   </div>
            <div className="g13"><a href="/malumot_3" className="all_a"> 
            <img 
            src={mudir}
           className="gimg"
           alt="Рустамова"
            />

                 
                  <div className="gname">Рустамова Хуршедабону</div>
                  <div className="gname">Раҳимовна </div>
<div className="gstatus">дотсент</div>
<div className="gabout">н.и.и., мудири кафедра </div>
        

 
</a>      </div>
            <div className="g14"><a href="/malumot_4" className="all_a"> 
            
 <img 
            src={AH}
            alt="Ашӯров"
           className="gimg"
            />

               
                  <div className="gname">Ашӯров Хуршед</div>
                  <div className="gname">Мирумарович </div>
<div className="gstatus">дотсент</div>
<div className="gabout">н.и.и.  </div>
 

</a>   </div>
              <div className="g15"  >  <a href="/malumot_5" className="all_a"> 
           
 <img 
            src={OK}
            alt="Комилов"
           className="gimg"
            />

                
                 <div className="gname">Комилов Оқил</div>
                  <div className="gname">Одилович </div>
<div className="gstatus">дотсент</div>
<div className="gabout">н.и.ф.-м.</div>
 

           </a> </div>
            <div className="g16"><a href="/malumot_6" className="all_a"> 
          
 <img 
            src={SHZ}
            alt="Шарифзода"
           className="gimg"
            />

                  
                  <div className="gname">Шарифзода Зебонисои</div>
                  <div className="gname">Иброҳим </div>
<div className="gstatus">муаллими калон</div>
<div className="gabout">н.и.ф.-м</div>
 
 
</a>  </div>
            <div className="g17"><a href="/malumot_7" className="all_a"> 
       
 <img 
            src={PS}
            alt="Пиров"
           className="gimg"
            />

                 
                  <div className="gname">Пиров Саидалӣ</div>
                  <div className="gname">Мирзоалиевич </div>
<div className="gstatus">муаллими калон</div>
<div className="gabout">аспиранти кафедра  </div>
 
 
</a>  </div>
            <div className="g18"><a href="/malumot_8" className="all_a"> 
          
 <img 
            src={GN}
           className="gimg"
           alt="Ҷумаева"
            />

                
                  <div className="gname">Ҷумаева Нигина</div>
                  <div className="gname">Эраҷовна</div>
<div className="gstatus">муаллими калон</div>
<div className="gabout">аспиранти кафедра  </div>
 
 
</a>  </div>
            <div className="g19"><a href="/malumot_9" className="all_a"> 
         
 <img 
            src={KN}
           className="gimg"
           alt="Қурбонова"
            />

                  
                  <div className="gname">Қурбонова Наҷотбегим </div>
                  <div className="gname">Маризоевна</div>
<div className="gstatus">ассистент</div>
<div className="gabout">аспиранти кафедра  </div>
 
 
</a>   </div>
            <div className="g20"><a href="/malumot_10" className="all_a"> 
             
 <img 
            src={MS}
            alt="Мусоев"
           className="gimg"
            
           
            />
                  <div className="gname">Мусоев Суҳайлӣ</div>
                  <div className="gname">Сайвалиевич</div>
<div className="gstatus">ассистент</div>
<div className="gabout">аспиранти кафедра </div>
 
 
</a> </div>
            </div>
            </div>   
          
  
        
          
          </div>







)

}  export default Omuzgoron;