import React from 'react';
import './Modalkh.css';
function Modalkh({ closeModalkh }){
    return(
       <div className='modalBackgroundkh'> 
       <div className='modalContainerkh'> 
       <div className='titleCloseBtnkh'>
         <button  onClick={() => closeModalkh(false)}>×</button>
       </div>
      <p>Руйхати корҳои хатмкунӣ 2024-2025</p> 
       <div className='jdkk'> 
<table>
<tr className='ttd'>
    <td > </td>
    <td  ></td>
    <td  > Ихтисоси 98010101</td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Абдизода Аҳмад</td>
    <td>Протоколҳои шабакавӣ ва ҳифз дар онҳо</td>
    <td>н.и.ф.-м., м.к.Шарифзода З.</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Алимаҳмадов Муҳаммад</td>
    <td>Мушкилии ададҳои калон дар забони Python</td>
    <td>асс.Қурбонова Н.</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Ачилов Зиёдулло</td>
    <td>Татбиқи рамзҳои содатарин бо истифода аз забони барномасозии Python</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Бозоров Шараф</td>
    <td>Таҷҳизоти биометрӣ ва истифодаи онҳо дар амалия</td>
    <td>н.и.ф.-м., дотс.Комилов О.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Ғафурзода Сунатулло </td>
    <td>Алгоритми Евклид ва истифодаи он дар криптография</td>
    <td>н.и.ф.-м., м.к.Шарифзода З.</td>
  </tr>
  <tr>
    <td>6</td>
    <td>Исаков Саидҷон</td>
    <td>Татбиқи усулҳои стеганографӣ дар муҳити забони барномасозии Delphi 2010</td>
    <td>н.и.и., дотс.Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Қурбонов Акбаралӣ</td>
    <td>Татбиқи пакетҳои стандартӣ дар ҳалли масъалаҳои ҳифзи ахбор</td>
    <td>д.и.ф.-м., проф.Нуров И.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Маҳмадалиев Саидакбар</td>
    <td> Истифодаи технологияи иттилоотӣ ва иртиботӣ барои таъмини 
      ҳалли масъалаи захираҳои техникӣ </td>
    <td>н.и.и., дотс.Қурбонов К.</td>
  </tr>
  <tr>
    <td>9</td>
    <td>Мирзоев Абдулатиф</td>
    <td>Татбиқи рамзи Атбаш бо забони барномасозии Python</td>
    <td>д.и.т., проф.Шерматов Н.</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Мирзобоев Нодирбек</td>
    <td>Таҳлили рамзи Вижинер ва татбиқи он дар забони барномасозии Delphi 2010</td>
    <td>н.и.и., дотс.Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Олимзода Фарҳод </td>
    <td> Навишти оптималии барнома бо истифода аз забони барномасозии Python</td>
    <td>д.и.ф.-м., проф.Нуров И.</td>
  </tr>
  <tr>
    <td>12</td>
    <td>Очилдиев Муҳаммадҷон</td>
    <td>    Татбиқи усулҳои содатарини ҳифз дар барномаҳои офисӣ бо истифода аз методҳои криптографӣ</td>
    <td>н.и.ф.-м., м.к.Шарифзода З.</td>
  </tr>
  <tr>
    <td>13</td>
    <td>Раҳимова Нодира</td>
    <td>Таҳияи системаи таъмини бехатарии додаҳо</td>
    <td>м.к.Пиров С.М.</td>
  </tr>
   
  <tr>
    <td>14</td>
    <td>Сангова Парвона</td>
    <td>Таҳлили муқоисавии алгоритмҳои симметрӣ ва асимметрии рамзбандӣ </td>
    <td>асс.Мусоев С.</td>
  </tr>
  <tr>
    <td>15</td>
    <td>Файззода Мафтуна</td>
    <td>Воситаи техникии ҳифзи иттилоот</td>
    <td>н.и.и., дотс.Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>16</td>
    <td>Ҳасанов Бузургмеҳр</td>
    <td>Усулҳои ҳифз дар таҷҳизоти мобилӣ</td>
    <td>н.и.и., дотс.Ашуров Х.</td>
  </tr>
  <tr>
    <td>17</td>
    <td>Ҳусензода Ҳусейнбой</td>
    <td>Имкониятҳои забони барномасозии Delphi ва C# дар сохтани барномаҳои зараррасони одитарин</td>
    <td>н.и.ф.-м., дотс.Комилов О.О.</td>
  </tr>
  <tr>
    <td>18</td>
    <td>Юсупов Комрон </td>
    <td> Татбиқи алгоритми рамзгузорӣ дар забони барномасозии C#</td>
    <td>н.и.и., дотс.Ашуров Х.</td>
  </tr>
  <tr>
    <td>19</td>
    <td>Ҷовидони Акрам </td>
    <td> Таҳқиқи методҳои рамзгузорӣ дар СИПД</td>
    <td>м.к.Пиров С.</td>
  </tr>
   
  
  <tr className='ttd'>
    <td > </td>
    <td  >Ихтисоси 21050103 </td>
    <td  > </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Ахмедзода Салоҳиддин</td>
    <td>Сохтори калимаҳои мураккаб дар “Бӯстон”-и Саъдии Шерозӣ ва таҳияи барномаи он</td>
    <td>д.и.ф., профессор Саломиён М.Қ., н.и.ф.-м., м.к. Шарифзода З.И.
.</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Шарипов Рамазон</td>
    <td>Сохтори калимаҳои сохта дар “Баҳористон”-и Абдурраҳмони Ҷомӣ ва таҳияи барномаи он </td>
    <td>д.и.ф., профессор Саломиён М.Қ., н.и.и., дотсент Рустамова Х.Р. 
</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Саидов Эҳсон</td>
    <td>Мақоми вандҳои феълсоз дар осори Убайди Зоконӣ ва таҳияи барномаи он</td>
    <td>д.и.ф., профессор Ҳомидов Д.Р., н.и.ф.-м., дотсент Комилов О.О.
</td>
</tr>
  <tr>
    <td>4</td>
    <td>Самиев Муниршоҳ </td>
    <td> Баъзе вижагиҳои луғавии “800 ғазали дилошуб”-и Ҷалолиддини Балхӣ ва таҳияи барномаи он</td>
    <td>д.и.ф., профессор Ҳомидов Д.Р., н.и.и., дотсент Рустамова Х.Р.
</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Нурзода Фарида</td>
    <td>Типологияи дискурс ва технологияҳои иттилоотӣ</td>
    <td>д.и.ф., профессор Мухторов З.М., м.к. Пиров С.М.
</td>
  </tr>
  <tr>
    <td>6</td>
    <td>Ҳасанов Абдумаҷид</td>
    <td>Истилоҳоти байналмилалӣ дар соҳаи технологияҳои иттилоотӣ</td>
    <td>д.и.ф., профессор Исматуллозода Ш.И., н.и.ф.-м., дотсент Комилов О.О.

</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Шоева Шаҳло</td>
    <td>Корбурди истилоҳоти сахтафзор дар соҳаи технологияҳои иттилоотӣ</td>
    <td>д.и.ф., профессор Исматуллозода Ш.И., н.и.ф.-м., м.к. Шарифзода З.И.


</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Гулзода Азиза</td>
    <td>Таҳияи барномаи электронии муродифоти луғавии “Қобуснома”-и Унсурулмаолии Кайковус</td>
    <td>д.и.ф., профессор Гулназарзода Ж.Б., н.и.и., дотсент Ашуров Х.М.


</td>
  </tr>

  <tr>
    <td>9</td>
    <td>Игамбердизода Шабнам</td>
    <td>Таҳияи барномаи муродифоти луғавӣ дар ғазалиёти Хоҷуи Кирмонӣ</td>
    <td>д.и.ф., профессор Шарифзода Ф.Х., н.и.и., дотсент Қурбонов К.Ю.
</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Олимов Мавзунбек</td>
    <td>Асосҳои лингвистӣ ва иттилоотӣ-иртиботии таҳияи “Фарҳанги басомади вожагони “Баҳористон”-и Ҷомӣ</td>
    <td>н.и.ф., дотсент Мирбобоев А., н.и.ф.-м., дотсент Комилов О.О.
</td>
  </tr>


  <tr>
    <td>11</td>
    <td>Ёрова Райҳона</td>
    <td>Асосҳои лингвистӣ ва иттилоотӣ-иртиботии таҳияи “Фарҳанги басомади вожагони ашъори Рӯдакӣ</td>
    <td>н.и.ф., дотсент Мирбобоев А., асс. Мусоев С.С.
</td>
  </tr>

  <tr>
    <td>12</td>
    <td>Ғафурова Фархунда</td>
    <td>Вожаҳои соҳаи гулдӯзӣ ва таҳияи луғати электронии он</td>
    <td>н.и.ф., дотсент Ҳалимова С.Ш., асс. Қурбонова Н.М.
</td>
  </tr>
  <tr>
    <td>13</td>
    <td>Парвиззода Манижа</td>
    <td>Таҳияи барномаи электронии муродифоти луғавии ашъори Унсурӣ</td>
    <td>н.и.ф., дотсент Саъдуллозода У.Қ., н.и.ф.-м., дотсент Комилов О.О.
</td>
  </tr>
  <tr>
    <td>14</td>
    <td>Ҳакимова Сафия</td>
    <td>Таҳияи барномаи электронии вожаҳои зидмаънои ашъори Унсурӣ</td>
    <td>н.и.ф., дотсент Саъдуллозода У.Қ., н.и.ф.-м., м.к. Шарифзода З.И.

</td>
  </tr>
  <tr>
    <td>15</td>
    <td>Назриев Маҳмадулло</td>
    <td>Таҳияи роҳнамои мобилии “Забоншиносии тоҷик”</td>
    <td>н.и.ф., дотсент Камолзода Х.М., м.к. Пиров С.М.
</td>
  </tr>
  <tr>
    <td>16</td>
    <td>Ниёзов Исмоил</td>
    <td>Таҳияи сомонаи расмии кафедраи таърихи забон ва типологияи ДМТ</td>
    <td>н.и.ф., дотсент Камолзода Х.М., н.и.и., дотсент Рустамова Х.Р.
</td>
  </tr>

  <tr>
    <td>17</td>
    <td>Бобокалонзода Абдулматин</td>
    <td>Таҳияи фарҳанги басомади калимаҳо дар “Бӯстон”-и Саъдии Шерозӣ</td>
    <td>н.и.ф., муал.калон Ҷобиров А.Б., м.к. Пиров С.
</td>
  </tr>
  <tr>
    <td>18</td>
    <td>Муродализода Шаҳром</td>
    <td> </td>
    <td>н.и.ф., муал.калон Ҷобиров А.Б., м.к. Ҷумаева Н.Э.

</td>
  </tr>

  <tr>
    <td>19</td>
    <td>Шамсиев Шамсимаҳмад</td>
    <td>Таҳияи фарҳанги басомади калимаҳо дар “Гулистон”-и Саъдии Шерозӣ</td>
    <td>н.и.ф., муал.калон Ҷобиров А.Б., н.и.и., дотсент Рустамова Х.Р.
</td>
  </tr>

  <tr>
    <td>20</td>
    <td>Манзураи Асатилло</td>
    <td>Таҳияи луғати электронии вожаҳои касбу ҳунар (дар асоси “Хамса”-и Низомии Ганҷавӣ)</td>
    <td>асс.Муминова К.М., м.к. Ҷумаева Н.Э.

</td>
  </tr>


  <tr className='ttd'>
    <td > </td>
    <td  >Ихтисоси 02030702 </td>
    <td  > </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Ғоибзода С.</td>
    <td>Таҳлили сохтории истилоҳоти мансуби "роҳбарият, маъмурият ва биноҳои муассисаҳои таълимӣ" дар забонҳои тоҷикию чинӣ ва таҳияяи фарҳанги электронии он.</td>
    <td>Асозода Ҳ., Рустамова Х.Р.
</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Бурҳонов Б.</td>
    <td>Таркиби морфологии зарфи тарзи  амал дар забонҳои тоҷикию чинӣ ва таҳияи барномаи компютерии он.</td>
    <td>Маҳмадов Ҷ., Пиров Саидалӣ </td>
  </tr>
  <tr>
    <td>3</td>
    <td>Ёров И.</td>
    <td>Нақши неологизмҳо дар забони тоҷикӣ ва роҳҳои истифодаи он дар забони чинӣ.</td>
    <td>Маҳмадов Ҷ., Пиров Саидалӣ</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Мирзошарифзода С.</td>
    <td>Таҳлили лексикию семантикии истилоҳи хешутаборӣ дар забонҳои гуногунсохтор (дар асоси маводи забонҳои тоҷикию чинӣ) ва фарҳангӣ электронии он.</td>
    <td>Ибрагимов А., Қурбонов K.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Боймуродов Ш.</td>
    <td>Шаклҳои тасрифшавандаи  феъл дар забонҳои тоҷикӣ ва чинӣ, барномарезии он дар муҳити VS C#</td>
    <td>Қаҳорзода А., Комилов О. </td>
  </tr>
  <tr>
    <td>6</td>
    <td>Усмонова Н.</td>
    <td>Мавқеи истифодаи пайвандакҳои аммо дар ҷумлаи пайравии пуркунандаи забонҳои тоҷикию чинӣ ва коркарди компютерии он.</td>
    <td>Ғиёсзода С.,Рустамова X.
</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Раҷабов Х.</td>
    <td>Мавқеи ҷумлаҳои унвонӣ  дар забонҳои  тоҷикӣ ва чинӣ. Сохтани маълумотномаи электронӣ.</td>
    <td>Маҳмадов Ҷ., Комилов О.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Замонов М.</td>
    <td>Нақши зарбулмасалу мақолҳо дар шаклгирии ҷумлаҳои яктаркиба дар забонҳои тоҷикӣ  ва чинӣ. Таҳияи  Web-саҳифа</td>
    <td>Маҳмадов Ҷ.,Шарифзода З. 
</td>
  </tr>
  <tr>
    <td>9</td>
    <td>Мавҷудшоева С.</td>
    <td>Корбурди воситаҳои асосии алоқаи ҷумлаи пайрави пуркунанда бо сарҷумла дар забонҳои
       тоҷикӣ  ва роҳҳои ифодаи онҳо дар забони чинӣ. Таҳияи барномаи компютерӣ.</td>
    <td>Асозода Ҳ.,Шарифзода З.
</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Ҷобирзода Т.</td>
    <td>Хусусиятҳои функсионалӣ ва услубии ҷумлаҳои пайрав дар забонҳои тоҷикӣ ва чинӣ, барномарезӣ дар муҳити 
Delphi 2010
</td>
    <td>Назиров Ш.,Комилов О.
.</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Гадоев М.</td>
    <td>Масъалаи омӯзиши мавқеи хусусиятҳои семантикии 
      воҳидҳои фразеологии соматикӣ дар забонҳои тоҷикиву чинӣ (бо ҷузъҳои даст, сар ва дил) ва барномарезии он.</td>
    <td>Ибрагимов А., Мусоев С. </td>
  </tr>

  <tr>
    <td>12</td>
    <td>Маҳмудзода Т.</td>
    <td>Таҳлили семантикии ибораҳои феълӣ  дар забонҳои  тоҷикиву чинӣ ва таҳияи луғати бехат (offline)</td>
    <td>Назиров Ш., Рустамова Х.
 </td>
  </tr>


  <tr>
    <td>13</td>
    <td>Нурматов З.</td>
    <td>Таҳлили лексикию семантикии воҳидҳои забон дар системаи 
       истилоҳоти компютерӣ дар забонҳои тоҷикию чинӣ ва таҳияи луғати электронии бархати  он.</td>
    <td>Ғиёсзода С., Пиров С. </td>
  </tr>


  <tr>
    <td>14</td>
    <td>Муҳиддинов А.</td>
    <td>Таҳлили семантикии консепсияи "осмон" дар забонҳои тоҷикию чинӣ, барномарезии он </td>
    <td>Ибрагимов А.,Ҷумаева Н.
 </td>
  </tr>

  <tr>
    <td>15</td>
    <td>Абдулбадров З.</td>
    <td>Тарзи сохторӣ ва грамматикии мафҳуми “сар” 
      дар воҳидҳои фразеологии забонҳои тоҷикиву чинӣ ва таҳияи барномаи он дар муҳити Embarcadero.</td>
    <td>Назиров Ш., Шодибекова М. </td>
  </tr>


  <tr>
    <td>16</td>
    <td>Ҳафизов А.</td>
    <td>Манбаъ ва маншаи иероглифҳо дар забони чинӣ  ва маълумотномаи он дар Web.</td>
    <td>Назиров Ш., Шодибекова М. </td>
  </tr>


   
  
</table>

      </div>
        
       
       </div> 
       </div>
        

     
    )
}

export default Modalkh;