import './Footer.css';
import yes from './../img/1.png';
import no from './../img/0.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaBus } from 'react-icons/fa';
import axios from 'axios';

function Footer() {
  const [plus, setPlus] = useState(0); // Количество лайков
  const [minus, setMinus] = useState(0); // Количество дизлайков
  const [likeActive, setLikeActive] = useState(false); // Состояние кнопки лайк
  const [dislikeActive, setDislikeActive] = useState(false); // Состояние кнопки дизлайк

  // Проверка состояния кнопок и значений из localStorage при загрузке страницы
  useEffect(() => {
    const storedLike = localStorage.getItem('likeActive');
    const storedDislike = localStorage.getItem('dislikeActive');
    const storedPlus = localStorage.getItem('plus');
    const storedMinus = localStorage.getItem('minus');
    
    if (storedLike === 'true') {
      setLikeActive(true);
      setPlus(prev => prev + 1); // Увеличиваем лайк
    }
    
    if (storedDislike === 'true') {
      setDislikeActive(true);
      setMinus(prev => prev + 1); // Увеличиваем дизлайк
    }

    if (storedPlus) setPlus(parseInt(storedPlus));
    if (storedMinus) setMinus(parseInt(storedMinus));

    // Загружаем начальное количество лайков
    fetch("https://dmttii.tj/api/plus.php", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ action: 1 })
    })
    .then(response => response.text())
    .then(response => setPlus(parseInt(response)));

    // Загружаем начальное количество дизлайков
    fetch("https://dmttii.tj/api/minus.php", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ action: 1 })
    })
    .then(response => response.text())
    .then(response => setMinus(parseInt(response)));

  }, []);

  const handleLike = () => {
    if (!likeActive) { // Если лайк не активен
      setPlus(plus + 1); // Увеличиваем количество лайков
      setLikeActive(true); // Делаем кнопку лайк активной
      localStorage.setItem('likeActive', 'true'); // Сохраняем в localStorage
      localStorage.setItem('plus', plus + 1); // Сохраняем количество лайков в localStorage
      const url = "https://dmttii.tj/api/in.php";
      let fData = new FormData();
      fData.append('str', 1);
      axios.post(url, fData)
        .then(response => console.log(response))
        .catch(error => alert(error));
    }
  };

  const handleDislike = () => {
    if (!dislikeActive) { // Если дизлайк не активен
      setMinus(minus + 1); // Увеличиваем количество дизлайков
      setDislikeActive(true); // Делаем кнопку дизлайк активной
      localStorage.setItem('dislikeActive', 'true'); // Сохраняем в localStorage
      localStorage.setItem('minus', minus + 1); // Сохраняем количество дизлайков в localStorage
      const url = "https://dmttii.tj/api/in2.php";
      let fData = new FormData();
      fData.append('str', -1);
      axios.post(url, fData)
        .then(response => console.log(response))
        .catch(error => alert(error));
    }
  };

  return (
    <div className="footer">
      <div className="footer_top">
        <div className="lef" id="tamos">
          <h2>ТАМОС</h2>
          <table>
            <tr>
              <td className="td_img">
                <FaMapMarkerAlt className="faicon" />
              </td>
              <td className="textfooter">Ҷумҳурии Тоҷикистон, 734042, Душанбе</td>
            </tr>
            <tr>
              <td></td>
              <td className="textfooter">Буни Ҳисорак, Шаҳраки донишҷӯён (бинои №17)</td>
            </tr>
            <tr>
              <td className="td_img"><FaRegClock className="faicon" /></td>
              <td className="textfooter">8:00 - 17:00</td>
            </tr>
            <tr>
              <td className="td_img"><FaPhoneAlt className="faicon" /></td>
              <td className="textfooter">+992</td>
            </tr>
            <tr>
              <td className="td_img"><FaPaperPlane className="faicon_mail" /></td>
              <td className="textfooter">
                <NavLink href="/Mail"><a className="amail">kafedraitii@gmail.com</a></NavLink>
              </td>
            </tr>
            <tr>
              <td className="td_img"><FaBus className="faicon" /></td>
              <td className="textfooter">М.№-8,10,17,54,А.№-8,10</td>
            </tr>
          </table>
        </div>
        <div className="righ">
          <div className='footer_title'>
            <ul>
              <li className='li'>
                <img
                  src={yes}
                  height='50'
                  width='50'
                  className={likeActive ? 'active' : ''}
                  alt='logo'
                  onClick={handleLike}
                />
                <div className='vertline'></div>
                <img
                  src={no}
                  height='50'
                  width='50'
                  className={dislikeActive ? 'active' : ''}
                  alt='logo'
                  onClick={handleDislike}
                />
              </li>
              <li>
                <span>{plus}</span>
                <span>{minus}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer_line">
        <ul>
          <li><p>© 2025 ДМТ. Кафедраи ТИИ</p></li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
