import React, {useState, useEffect} from 'react';
import './barnomasozi.css';
 
import bio from './../img/bio.png';
import Djobs from './../img/Djobs.jpg';
 
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
    FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaLongArrowAltRight, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaCheckDouble, FaCheckSquare, FaCheck} from 'react-icons/fa';

    




    function Barnomasozi() {
      let msg="";
 
    
 
   
    
    

       


return(
    <div className="item_8" data-aos="fade-zoom-in">
  
      
      <div className='section_81'>
    
           
      <div className='item_81'> 
      <h1>Барномасозӣ</h1>
      <div className='text_81'>
 <p>Барномасозӣ - ин раванди эҷод ва тағйир додани барномаҳои компютерӣ.</p>
 <ul className='sd'>
 <li className='Djobs'><img 
           src={Djobs}
           alt="stiv"
           width="100%"
           height="100%"
                      /></li>
      <li className='pleft'><p>Барномасозӣ ин худ санъат аст, санъати фикрронӣ ва эҷодкунӣ. 
            Пас ҳар як нафар бояд ба омӯзиши барномасозӣ машғул гардад, чунки ин ба ӯ  фикррониро меомӯзад.</p>
             <a>Стив Джобс </a>
            </li>
      
 </ul>
 
      </div>
      </div>
      <div className='item_82'>
 
      <div className='text_82'>
            <div className='left_82'> Frontend </div>
            <div className='center_82'>Backend</div>
            <div className='right_82'><FaLongArrowAltRight className="FaReg"/> Full Stack</div>
      </div>
             </div>
       
      <div className='item_83'> 
      <div className='text_830'>
            <ul>
                  <li><p><a className='az'>Frontend</a> ин қисми ба чашм айёни барнома ё маҳсули ададӣ буда, 
                  <a className='az'> Backend</a> қисми барномавию таҷҳизотии он мебошад ва он аз чашми корбар 
                        (ё истифодабаранда) пинҳон аст. 
Дар <a className='az'>Frontend</a> коде навишта мешавад, ки дар браузер кор мекунад ва танҳо ин код чизеро, ки 
 дар ниҳоят истифодабаранда хоҳад дид, муайян месозад.

</p></li>
                  <li><p><a className='az'>Backend </a>ҳам худ коди навишташуда буда, 
                        барои сервер, ки дар он ҷо дархостҳои истифодабарандагон коркард мегарданд, муқаррар гаштааст.</p></li>
                  <li><p>
Дар соҳаи барномасозӣ “сарбозони универсалӣ” – <a className='az'>Full Stack </a>таҳиягарон дида мешаванд, ки онҳо ҳам маҳорати 
бо <a className='az'>Frontend</a> ва ҳам бо <a className='az'>Backend</a> кор карданро доранд.
</p></li>
            </ul>
            


      </div>
               <div className='tiob'><span>Машҳурияти забонҳои барномасозӣ дар <a href="#tiobe">TIOBE Index</a></span></div> 
      </div>  
     
      </div> 
      
</div> 
    
    )
  }  export default Barnomasozi;