import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './../img/t3.png';
import identif from './../img/identif.png';
import adduser from './../img/adduser.png';
import settings from './../img/settings.png';
import update from './../img/update.jpg';
import exit from './../img/exit.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons';
import tiobe_index from './../img/tiobe_index.png';
import './HeaderTest.css';
import React, { useEffect, useState } from 'react'
  
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, 
  FaMailBulk, FaUser, FaUsers, FaUserCircle, FaUserPlus, FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, 
  FaStethoscope, FaCogs, FaUserSecret, FaSearch, FaUniversity, FaSchool, FaSitemap, FaUserTie, FaAngleRight, FaCalendar, FaCalendarCheck, FaCalendarDay, FaCalendarWeek, 
  FaFile, FaFileAlt, FaFileArchive, FaFileCode, FaFileCsv, FaFilePdf, FaFileImport, FaFileWord, FaFileContract, FaFileDownload, 
  FaSistrix, FaSignInAlt, FaCog, FaHome, FaDatabase, FaPhotoVideo, FaAddressBook, FaTable, FaMailchimp, FaAngleDown, FaSign, FaUpload, FaAd, FaCheck, FaPlus} from 'react-icons/fa';


import { BrowserRouter as Router, Switch, Route, Link}  from 'react-router-dom';
 
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
 
import ModalTest from './ModalTest';
import { useRef } from "react";
import {FormGroup, FormLabel, FormControl} from 'react-bootstrap'
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
 
 






function NavTest() {
  let navigate = useNavigate();
const [auth, setAuth] = useState('');
const [user, setUser] = useState('');


const ref1 = useRef();
 
const ref2 = useRef();
 
const ref3 = useRef();
 
const ref4 = useRef();


const ref5 = useRef();
const ref6 = useRef();












useEffect(()=>{
  
  var userName = localStorage.getItem('userName');
 

  
  setUser(userName);
  
  if (userName){
 
  ref1.current.style.display = "none";
 
  ref2.current.style.display = "none";
  ref3.current.style.display = "none";
  ref4.current.style.display = "none";
  ref5.current.style.display = "block";
  ref6.current.style.display = "flex";
  }

},
[])

 
const LogOut=()=>{
 
  let keysToRemove = ["email", "userName"];

  keysToRemove.forEach(k =>
      localStorage.removeItem(k))


  navigate(`/Login`);
navigate(`/`);
}


 
const[openModalTest, setOpenModalTest]=useState(false)


  const [fix, setFix]=useState(false);
  function setFixed(){
    if (window.scrollY>=40)
    {
      setFix(true)
    }
    else
    {
      setFix(false)
    }
    }
 window.addEventListener("scroll", setFixed)
 
 const [query, setQuery] = useState(""); // Состояние для хранения текста поиска
 
  

  // Обработчик поиска
  const handleSearch = (e) => {
    e.preventDefault(); // Предотвращаем перезагрузку страницы
    console.log("Поиск по запросу:", query); // Здесь можно добавить логику для реального поиска
  }; 


   
  return (
    <div className={fix ? 'navbar_fixed2' : 'navbar_all2'}> 
  
    {openModalTest && <ModalTest closeModalTest={setOpenModalTest} />}
 
        <Navbar className="nb2"   variant="dark" expand="lg"> 
     <div  className='menuTest'> 
               <div className='dlogoTest'><img 
                    src={logo}
                    height='30'
                    width='130'
                    className=''
                    alt='logo'
                    />    
                 </div>   
      <ul className='barTest'>       
        <li className='asosi'><span ><NavLink   href="/"> Асосӣ</NavLink></span></li>
        <li className='libar'> <a className="" onClick={()=>{ setOpenModalTest(true)}}> <FaCheck className="iconsmenu3"/>Дохилшавӣ</a> </li>
        <li className='libar'><a className="" onClick={()=>{ setOpenModalTest(true)}}> <FaPlus className="iconsmenu3"/>Иловакунӣ</a>  </li>
        <li className='libar'> <a className="" onClick={()=>{ setOpenModalTest(true)}}><FaDatabase className="iconsmenu3"/>Натиҷа</a></li>
     </ul>
      </div>                 
  <div class="hamburger-menuTest">
    <input id="menu__toggleTest" type="checkbox" />
    <label class="menu__btnTest" for="menu__toggleTest">
      <span></span>
    </label>
  <ul class="menu__boxTest">
      <li><a class="menu__itemTest" href="/"><FaHome className="iconsmenuTest"/>Асосӣ</a></li>
      <li><a class="menu__itemTest" onClick={()=>{ setOpenModalTest(true)}}><FaCheck className="iconsmenuTest"/>Дохилшавӣ</a></li>
      <li><a class="menu__itemTest" onClick={()=>{ setOpenModalTest(true)}}><FaPlus className="iconsmenuTest"/>Иловакунӣ</a></li>
      <li><a class="menu__itemTest" onClick={()=>{ setOpenModalTest(true)}}><FaDatabase className="iconsmenuTest"/>Натиҷа</a></li>
    </ul>
  </div>  
    </Navbar>


   
    
                            
        

 

        

               

 
 

                 
    </div>






  );
}







export default NavTest;

 

      
  
  
 

 