import React, { useState } from 'react'
import {Form, Button, Container, FormGroup, FormLabel, FormControl} from 'react-bootstrap'
import { NavLink } from 'react-bootstrap';
 
import Footer from '../Components/Footer';
import Header from '../Components/Header';
 
import './m_mili.css';
import parch from './../img/parch.jpg';
import nishon from './../img/nishon.png';
import konst from './../img/konst.jpg';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
    FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
     FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
      FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
      FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
       FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
export const M_mili=()=>(
 
 <div >
 
<Header/>
<h1 className="text-mm">Муқаддасоти миллӣ</h1>  
   <div className='modalBackgroundmm'> 
   <div className='modalmContainermm'> 
   
   
     
     
  
  <Container className='Contmm'>
    <div className='dp'>
      
  <div className='parch'>
  
  <img 
            src={parch}
           className="parch_img"
            />
<p>
<b>Парчами давлатии </b> Ҷумҳурии Тоҷикистон — рамзи истиқлоли давлатии Ҷумҳурии Тоҷикистон мебошад.

Парчами Ҷумҳурии Тоҷикистон 24 ноябри соли 1992 пазируфта шудааст.   </p>


            <p>Ранги <a className='red'>сурх</a> - рамзи муборизаву ҷоннисории халқ барои озодӣ ва истиқлол, ранги<a className='wh'> сафед</a> - нишони
                 бахту саодат, умеду орзу ва ранги <a className='gr'> сабз</a> - нишонаи сарсабзиву шукуфоӣ, сарбаландиву хуррамӣ ва абадият мебошад. </p>
                 <p> Дар байни парчам тасвири тоҷ ва ҳафт ахтар ҷой гирифтааст, ки он ишора ба решаҳои таърихии давлатдории Тоҷикистони соҳибистиқлол мебошад.</p>
  </div></div>
  <div className='dp'> <div className='nish'>
  <img 
            src={nishon}
           className="nish_img"
            />

<p><b>Нишони давлатии </b>Ҷумҳурии Тоҷикистон рамзи истиқлоли давлатии Ҷумҳурии Тоҷикистон буда, дар он тоҷ, офтоб, кӯҳҳо, хӯшаҳои гандум, китоб ва курсӣ бо зарҳал тасвир ёфта, 
                появу баргҳои ниҳолҳои пахта сабз, рахҳои тасмаҳо сурх, сафед ва сабз буда, муқоваи китоб сурх мебошад.</p>
                
<p className='pa'><a className='red'>Пахта</a> - <i>боигарии кишвар</i></p>
<p className='pa'><a className='red'>Гандум</a> - <i></i>нишон аз ҳаёт ва хайру баракат</p>
<p className='pa'><a className='red'>Офтоб</a> - <i>нишон аз ҳаёти абадӣ, осоишта ва мамлакати офтобӣ</i></p>
<p className='pa'><a className='red'>Тоҷ</a> - <i>нишон аз соҳибдавлатӣ ва мустаққилият</i></p>
<p className='pa'><a className='red'>7 ситора</a> - <i>7 иқлим, 7 ситораи бародарон, 7 қабати осмон, 7 рӯз</i></p>
<p className='pa'><a className='red'>Кӯҳ</a> - <i>нишон аз кӯҳсории кишвар</i></p>




    </div></div>  
  <div className='dp'> <div className='sm'>
    <p><b>Суруди миллии Тоҷикистон</b> - композитсияи «Суруди миллӣ» мебошад. Ҳамчун суруди миллӣ аз соли 1994 истифода мешавад.</p>
<p>Муаллифи матн - Гулназар Келдӣ, муаллифи оҳанг - Сулаймон Юдаков</p>

<div className='sm_p'>
<p>Диёри арҷманди мо,</p>
<p>Ба бахти мо сари азизи ту баланд бод,</p>
<p>Саодати ту, давлати ту бегазанд бод.</p>
<p>Зи дурии замонаҳо расидаем,</p>
<p>Ба зери парчами ту саф кашидаем, кашидаем.</p>

<p>Зинда бош, эй Ватан,</p>
<p>Тоҷикистони озоди ман!</p>

<p>Барои нангу номи мо</p>
<p>Ту аз умеди рафтагони мо нишонаӣ,</p>
<p>Ту баҳри ворисон ҷаҳони ҷовидонаӣ,</p>
<p>Хазон намерасад ба навбаҳори ту,</p>
<p>Ки мазраи вафо бувад канори ту, канори ту.</p>

<p>Зинда бош, эй Ватан,</p>
<p>Тоҷикистони озоди ман!</p>

<p>Ту модари ягонаӣ,</p>
<p>Бақои ту бувад бақои хонадони мо,</p>
<p>Мароми ту бувад мароми ҷисму ҷони мо,</p>
<p>Зи ту саодати абад насиби мост,</p>
<p>Ту ҳастиву ҳама ҷаҳон ҳабиби мост, ҳабиби мост.</p>

<p>Зинда бош, эй Ватан,</p>
<p>Тоҷикистони озоди ман!</p>

</div>







 
    
    </div></div>  
  <div className='dp'> <div className='konst'>
  <img 
            src={konst}
           className="konst_img"
            />
  <p><b>Конститутсияи Ҷумҳурии Тоҷикистон </b>6-уми ноябри соли 1994 дар раъйпурсии умумихалқӣ қабул карда шудааст. 26 сентябри соли 1999, 22 июни соли 2003 
  ва 22 майи соли 2016 бо тариқи раъйпурсии умумихалқӣ ба Конститутсияи Ҷумҳурии Тоҷикистон тағйиру иловаҳо ворид карда шудаанд. </p>
    <p> Дар моддаи 1-уми Конститутсияи Ҷумҳурии Тоҷикистон гуфта шудааст:</p>
<p>
«Ҷумҳурии Тоҷикистон давлати демократӣ, ҳуқуқбунёд, дунявӣ ва ягона мебошад. Тоҷикистон давлати иҷтимоӣ буда, 
барои ҳар як инсон шароити зиндагии арзанда ва инкишофи озодонаро фароҳам меорад. Ҷумҳурии Тоҷикистон ва Тоҷикистон ҳаммаъноянд.»
    </p>
    </div>  </div>  

  </Container>
    
   
   </div> 
   </div>
   <Footer/>
   </div>
     
    )
 

 