 
    import React from "react";
    import './mavodho.css';
    import mass from './../img/mass.png';
    import amn from './../img/amn.jpg';
    import python from './../img/python.png';
    import kurbonov2 from './../img/kurbonov2.jpg';
    import kurb from './../img/kurb.jpg';
    import rustamova from './../img/rustamova.jpg';
    import biom from './../img/biom.jpg';
    import delphi from './../img/delphi.jpg';
    import webs from './../img/webs.jpg';
    import mm from './../img/mm.jpg';
    import mmhb from './../img/mmhb.png';
    import st from './../img/st.jpg';
    import pirov from './../img/pirov.jpg';
    import jumaeva from './../img/jumaeva.jpeg';
    import jumaev from './../img/jumaev.jpg';
    import arabov from './../img/arabov.jpg';
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
      FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
       FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
        FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
        FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
         FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers} from 'react-icons/fa';
    
         import Footer from '../Components/Footer';
        
         import Header from '../Components/Header';
         import Nav from '../Components/Nav';
         import u_i from './../img/u_i.jpg';
         import o_s from './../img/o_s.jpg';
         import t_o from './../img/t_o.png';
         import z_m from './../img/z_m.jpg';
         import ch_m from './../img/ch_m.jpg';
         import s_v from './../img/s_v.jpg';
         import i_a from './../img/i_a.jpg';
    
    export const K_pr=()=>(
    <div class="mav">
      
        <Header />
    
    <section >
    
        
         <h1>Китобҳои Президенти Ҷумҳурии Тоҷикистон</h1> 
    <div className="mavod">
         <div className="mavod_grid">
    <div className="book">
        <div className="nomer">1</div>
    <img 
                src={u_i}
               className="imgbook"
                />
                <div className="aboutbook">
                <div className="titlebook">«Уфуқҳои Истиқлол»</div>
                <div className="autorbook"><a className="muallif">Муаллиф: </a>Эмомалӣ Раҳмон</div>
 
                <div className="commentbook"> 
                <p>
 Дар китоби арзишманди Асосгузори сулҳу ваҳдати миллӣ, Пешвои миллат, 
Президенти Чумҳурии Тоҷикистон муҳтарам Эмомалӣ Раҳмон <a className='red'>«Уфуқҳои Истиқлол» </a>дар бораи бурду бохт 
ва комёбиву дастовардҳои кишварамон дар чаҳоряк асри Истиқлолияти давлатӣ, марҳалаи эҳё ва барқарорсозии
 давлати миллии тоҷикон, рушди иқтисодии мамлакат, сиёсати иҷтимоӣ ва натиҷаҳои умедбахши он, Ваҳдати миллӣ 
 ва суботи иҷтимоӣ, манфиатҳои миллӣ ва 
сиёсати хориҷӣ, мақоми Тоҷикистон дар ҷаҳони муосир ва фардои дурахшони кишвари соҳибистиқлоламон сухан меравад. </p>


            <p>Китоб барои сиёсатшиносону муаррихон, муҳаққиқону коршиносони масоили сиёсӣ, иқтисодӣ, 
              иҷтимоӣ, байналмилалӣ, рӯзноманигорон ва умуман, доираи васеи хонандагон пешбинӣ гардидааст..</p>
    </div>
                <div className="yearbook">–Душанбе: «Ганҷ-нашриёт», 2018. – 436 с. </div>
                </div>
    
    </div>
    <div className="book">
    <div className="nomer">2</div>
    <img 
                src={o_s}
               className="imgbook"
                />
                <div className="aboutbook">
                <div className="titlebook">«Тоҷикон дар оинаи таърих. Аз Ориён то Сомониён»</div>
                <div className="autorbook"><a className="muallif">Муаллиф: </a>Эмомалӣ Раҳмон</div>
              
                <div className="commentbook"> 
                <p>
   
  Китоби мазкур тамоми сарчашмаҳои воқеии худшиносии миллӣ, қабл аз ҳама, бунёди давлати соҳибистиқлол, 
  ваҳдати миллӣ, рӯ овардан ба гузаштаи пурифтихор, ҳифзи тамаддуну мероси фарҳангии ниёгон ва посдории 
  хотири шахсиятҳои тавонову фарзандони бузурги миллатро дар бар гирифтааст. Китоб бо забонҳои тоҷикӣ ва русӣ нашр шудааст.
  </p>
    </div>
                <div className="yearbook">Муҳар.: С. Фаттоев, Б. Абдураҳмонов. – Душанбе: Ирфон, 2009. 
                – Китоби якум. –704 саҳ. </div>
                </div>
    
    </div>
    <div className="book">
    <div className="nomer">3</div>
    <img 
                src={t_o}
               className="imgbook"
                />
                <div className="aboutbook">
                <div className="titlebook">«Ориёиҳо ва шинохти тамаддуни ориёӣ»</div>
                <div className="autorbook"><a className="muallif">Муаллиф: </a>Эмомалӣ Раҳмон</div>
             
                <div className="commentbook">  Тамаддуни ориёӣ бо арзишҳои фарҳангиву ахлоқии хеш дар ташаккули тамаддуни башарӣ ва робитаи байни тамаддунҳо нақши бисёр муҳим дорад.

Тадқиқоти муаллиф дар иртибот бо ин тамаддуни бузург воқеияти корбурди истилоҳи таърихии ориё дар осори куҳан, таҳриф ва суиистифодаи 
номи “арйа” аз ҷониби нажодгароён дар асри ХХ, чигунагии раванди ташаккули
 забонҳои ориёӣ-эронӣ ва дигар масоили муҳими шинохти ин мардумонро баён мекунад, ки дар илми ҷаҳонӣ мавзӯи баҳси домандор аст.</div>
                <div className="yearbook"> Муҳар.: К. Абдураҳимов. – Душанбе: Шарқи озод, 2007. – 144 саҳ.</div>
                </div>
    
    </div>
    <div className="book">
    <div className="nomer">4</div>
    <img 
                src={z_m}
               className="imgbook"
                />
                <div className="aboutbook">
                <div className="titlebook">«Забони миллат – ҳастии миллат»</div>
                <div className="autorbook"><a className="muallif">Автор: </a>Эмомалӣ Раҳмон</div>
             
                <div className="commentbook">Китоб ба истиқболи 25-умин солгарди Истиқлолияти давлатии Тоҷикистон нашр гардидааст. Танҳо забон аст,
   ки дар ҳама давру замон таърихи воқеӣ ва ростини миллатро дар ҳофизаи худ нигоҳ медорад.
  
  Китоби мазкур аз мундариҷа ва 6 боб иборат аст: Боби якум “Даврабандии таърихии забони тоҷикӣ”, 
  боби дуюм “Саргузашти таърихии вожаи “Тоҷик” ва забони тоҷикӣ”, боби сеюм “Забони мо дар давраи истилои 
  араб ва нахустин сулолаҳои эронитабор”, боби чорум “Забони навиштории тоҷикӣ”, 
  боби панҷум “Давраи боландагӣ ва шукуфоии забони тоҷикӣ”, боби шашум “Густариши забони тоҷикӣ ба сифати 
  забони байналмилалӣ”-ро дар бар мегирад.</div>
                <div className="yearbook">Китоби 1. – Душанбе: Эр – граф, 2016. – 516 саҳ.</div>
                </div>
    
    
    </div>
    <div className="book">
    <div className="nomer">5</div>
    <img 
                src={ch_m}
               className="imgbook"
                />
                <div className="aboutbook">
                <div className="titlebook">«Чеҳраҳои мондагор» </div>
                <div className="autorbook"><a className="muallif">Муаллиф: </a>Эмомалӣ Раҳмон</div>
     
                <div className="commentbook">   
                Китоби мазкур дар асоси осори илмиву таърихӣ ва маърӯзаҳои Асосгузори сулҳу Ваҳдати миллӣ, Пешвои миллат, 
  Президенти Ҷумҳурии Тоҷикистон, муҳтарам Эмомалӣ Раҳмон мураттаб гардидааст. Мақсади таҳияи китоб 
  гирдоварии матнҳои комили маводи марбут ба фаъолият ва талошҳои ҳамарӯзаи Президенти кишвар барои дастрасӣ ва огоҳии бештари хонандагон ва
   ташкили маҷмӯи суханрониву маърӯза, номаҳои шодбошиву мусоҳибаҳо ва изҳороти Президенти Ҷумҳурии Тоҷикистон Эмомалӣ Раҳмон мебошад
 
    </div>
                <div className="yearbook">– Душанбе: ЭР – граф, 2016. – 364 саҳ.</div>
                </div>
    
    </div>
    <div className="book">
    <div className="nomer">6</div>
    <img 
                src={s_v}
               className="imgbook"
                />
                <div className="aboutbook">
                <div className="titlebook">«О современном мире и основных аспектах внешней политики Республики Таджикистан»</div>
                <div className="autorbook"><a className="muallif">Муаллиф: </a>Эмомали Рахмон</div>
             
                <div className="commentbook">  
                Дар китоби мазкур бори аввал матни ҳамаи қисматҳои паёмҳои солонаи Асосгузори сулҳу Ваҳдати миллӣ, Пешвои миллат, Президенти Ҷумҳурии 
  Тоҷикистон мӯҳтарам Эмомалӣ Раҳмон ба Парламони кишвар аз соли 2000 то 2018 оид ба сиёсати хориҷии Тоҷикистон гирдоварӣ шудаанд.
    Ин маҷмуаи президентӣ бо ташаббуси ҳайати таҳририи нашрияи “Народная газета” – мақоми чопии Ҳукумати Ҷумҳурии Тоҷикистон нашр шудааст.
Тартибдиҳанда муаллифи сарсухан доктори илмҳои таърих, мушовири калони бахши Ёрдамчии Президенти Ҷумҳурии Тоҷикистон оид 
ба робитаҳои хориҷӣ Зафар Сайидзода ва номзади илмҳои сиёсӣ, сардабири “Народная газета” Маҳбуба Камолова мебошанд. 
                
                </div>
                <div className="yearbook">– Душанбе: нашриёти «Контраст», 2019. – 222 саҳ.</div>
                </div>
    
    </div>
    
    <div className="book">
    <div className="nomer">7</div>
    <img 
                src={i_a}
               className="imgbook"
                />
                <div className="aboutbook">
                <div className="titlebook">«Имоми Аъзам: рӯзгор, осор ва афкор»</div>
                <div className="autorbook"><a className="muallif">Муаллиф: </a>Эмомалӣ Раҳмон</div>
                 
                <div className="commentbook"> 
                Дар ин китоб сайри мухтасар ё дар  таърихи омӯзиши аҳволу осор ва афкори яке аз барҷастарин чеҳраҳои шинохта дар соҳаи илмҳои исломӣ – асосгузор ва мураттиби фиқҳи исломӣ, яке аз аввалин мутаккалимон Абуҳанифа Нӯъмон бини Собит машҳур бо лақаби « Имоми Аъзам» сурат гирфитааст, то хонандаи азиз шиносоии кӯтоҳе дар бораи дараҷаву сатҳи омӯзиши ин мавзӯъ дошта бошад. Муаллиф дар соли бузургдошти Имоми Аъзам – соли 2009 тасмим гирифт, ки ин китобро ба хотири равшанӣ андохтан ба паҳлӯҳои зиндагӣ ва осори Имоми Аъзам таълиф намояд, 
  то хизмати хоксоронае дар ҷодаи омӯхтану муаррифии ин бузургмарди таърихи миллату динамон анҷом дода бошад.
                </div>
                <div className="yearbook">Мурат.: Ғ. Ғоибов; С. Аҳмадов; Таҳти назари А. Ализода. – Душанбе, «Эр- граф», 2009. – 416 саҳ.</div>
                </div>
    
    </div>
    
    
    
    
    
         </div>
                                </div>  
    
    
    
    
    
    
              
     
     
            
      
     
             
              
              </section>
    <Footer />
              </div>
    
    
    
    
    
    )
     