import React, { Component, useEffect } from 'react';
import {useState} from 'react';
import HashLoader from "react-spinners/HashLoader";
import './asosi.css';
import Slider from '../Components/Slider';
import slider1 from './../img/slider1.jpg';
import slider2 from './../img/slider2.jpg';
import slider3 from './../img/slider3.jpg';
import slider4 from './../img/slider4.jpg';

import mudir from './../img/mudir.png';
import NI from './../img/NI.png';
import AH from './../img/AH.png';
import OK from './../img/OK.png';
import SHZ from './../img/SHZ.png';
import PS from './../img/PS.png';
import kitob from './../img/kitob.jpg';

import KN from './../img/KN.png';
import MS from './../img/MS.png';
import GN from './../img/GN.png';
import KKn from './../img/KKn.png';

import mass from './../img/mass.png';
import mm from './../img/mm.jpg';
import python from './../img/python.png';
import pirov from './../img/pirov.jpg';

import st from './../img/st.jpg';
import Accordion from 'react-bootstrap/Accordion';

import {s_dmt} from './S_dmt';
import {m_mili} from './M_mili';
 



import ExampleCarouselImage from '../Components/ExampleCarouselImage';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { NavLink } from 'react-bootstrap';
import Footer from '../Components/Footer';
import Tiobe from '../Components/Tiobe';
import Samt from '../Components/Samt';
 
import Header from '../Components/Header';
import Nav from '../Components/Nav';
import Oid_kafedra from '../Components/Oid_kafedra';
import Habar from '../Components/Habar';
import SFIK from '../Components/SFIK';
import Seminar from '../Components/Seminar';
import Mavodho from '../Components/Mavodho';
import Omuzgoron from '../Components/Omuzgoron';
import Barnomasozi from '../Components/Barnomasozi';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
       FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
        FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
         FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
         FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
          FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
         
export const Asosi=()=>{
      useEffect(()=>{
            Aos.init({duration:2000});
      }, []);

      const [loading, setLoading] =useState(true)
      useEffect(()=>{
   setLoading(true)
   setTimeout(()=>{
   setLoading(false)
   }, 2000)
      }, [])
    

      return(
   /* loading   ?
             <HashLoader
           color="#3d3d3d"
           loading={loading}
           className='spiner'
           size={120}        
                   />   
      :  */
            
<div className="section_one">                
  
    <Header />
    <Nav />      
    <Oid_kafedra /> 
    <Habar /> 
    <SFIK /> 
    <Omuzgoron /> 
    <Seminar /> 
    <Mavodho />     
    <Samt />  
    <Barnomasozi />
   <Footer />
   
</div>
 
)

      }