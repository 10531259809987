import Modal from './Modal';
import Modalkh from './Modalkh';
import './mavodho.css';
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
 
import { BrowserRouter as Router, Switch, Route, Link}  from 'react-router-dom';
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
 
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
        FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
         FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
          FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
          FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
           FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
         
    
    function Mavodho() {
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        const[openModal, setOpenModal]=useState(false) 
        const[openModalkh, setOpenModalkh]=useState(false) 
        const [show_h, setShow_h] = useState(false);
        const handleClose_h = () => setShow_h(false);
        const handleShow_h = () => setShow_h(true);




return(
  
    <div className="mavodho">
  {openModal && <Modal closeModal={setOpenModal} />}
  {openModalkh && <Modalkh closeModalkh={setOpenModalkh} />}

<div className="item_7" data-aos="fade-zoom-in"> 
 
 <div className="h_item7">Маводҳо </div>
 

 
<div className="section_7">


<div className="s1" >
 <div className="line7"></div>
 <div className="title7"> <a href="/mavodho" className="aleft">Васоити таълимӣ</a> </div>
 
  
 <div className="text7">Васоити таълимии кафедра 
 аз тарафи омӯзгорони кафедра таҳия шуда...</div>
</div>

<div className="s2">
<div className="line7"></div>
 <div className="title7">Тестҳои санҷишӣ</div>
 <div className="text7">Тестҳои фаннӣ 
 аз тарафи омӯзгорони кафедра таҳия шуда...</div>
</div>

<div className="s3">

<div className="line7"></div>
 <div className="title7"> <a   onClick={()=>{ setOpenModal(true)}}> Мавзуи кори курсӣ</a>  
</div>
 
 <div className="text7">Мавзуҳои корҳои курсӣ аз тарафи кафедра пешниҳод мегардад..</div>
</div>
<div className="s4">

 
        
              
    



<div className="line7"></div>
 <div className="title7"><a onClick={()=>{setOpenModalkh(true)}}> Мавзуи кори хатм</a></div>
 <div className="text7">Мавзуи кори хатм аз тарафи кафедра пешниҳод мегардад..</div>
</div>


</div>
</div>
 
</div>
      
 
    
    )
  }  export default Mavodho;