import React  from 'react';
import './oid_kafedra.css';
import Slider from './Slider';
 
 
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
 
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
        FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
         FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
          FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
          FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
           FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
                  
     




    function Oid_kafedra() {
       

return(
    <div className="kafedra">
   <div className="item_1"  >
      <div className="lr" data-aos="fade">
    <div className="left">
    <h1>Оид ба кафедра</h1> 
           
            <p>Кафедраи технологияҳои иттилоотӣ ва иртиботӣ соли 2004 ташкил шуда, мудири аввалини кафедра
              н.и.ф.-м., дотсент (шодравон) Ашуров А.А. таъин гашт. Омӯзгорони кафедра ба ихтисосҳои
              донишгоҳӣ дар доираи талаботи нақшаҳои амалкунандаи таълимӣ аз фанҳои
              забонҳои барномасозӣ,
              технологияи иттилоотӣ, системаҳои иттилоотӣ ва ҷустуҷӯӣ, луғатсозии компютерӣ, web-дизайн, асосҳои технологияҳои муосири компютерӣ ва амнияти компютерӣ дарс медиҳанд.
              Онҳо барои ихтисосҳои “Амнияти компютерӣ”, ”Хитоӣ Информатика”
              ва «Забоншиносӣ ва технологияи нави иттилоотӣ» курсҳои тахассусӣ ва амалӣ мегузаранд. </p>
               
            <a href="/oidkafedra" className="aleft">Муфассал</a>
    </div>
    <div className="right"> < Slider />  </div>
    </div>
  
    </div>
      
</div> 
    
    )
  }  export default Oid_kafedra;