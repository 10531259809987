import React from 'react';
import './ModalTest.css';
 
import {Form, Button, Container, FormGroup, FormLabel, FormControl} from 'react-bootstrap'
function ModalTest({ closeModalTest }){
    return(
       <div className='modalmBackgroundTest'> 
       <div className='modalmContainerTest'> 
       <div className='titlemCloseBtnTest'>
           <button  onClick={() => closeModalTest(false)}>×</button>
       </div>
      <Container className='ContTest'>
       
        <Form>
          <Form.Group style={ {marginBottom:'15px'}} controlId="formBasicEmail">
            <Form.Label>         Ному насаб      </Form.Label>
            <Form.Control  style={ {fontSize:'13px', color:'rgb(121, 121, 121)'}} type="email" placeholder="namuna@mail.ru"    name="fullName"
      required/>
             
             </Form.Group>
             <Form.Group style={ {marginBottom:'15px'}} controlId="formBasicEmail">
            <Form.Label>         Курс (синф)      </Form.Label>
            <Form.Control  style={ {fontSize:'13px', color:'rgb(121, 121, 121)'}} type="text" placeholder="1/2/3/4"    name="course"
      required />
       <Form.Label style={{ marginTop:'17px'}}>         Интихоби мавзуъ     </Form.Label>
             <Form.Select 
      style={{ fontSize: '13px', color: 'rgb(121, 121, 121)' }}
      name="course"
      required
    >
      <option value="">Интихоб кунед</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
    </Form.Select>
             </Form.Group>
        
            <button variant="primary" type="submit">Гузаштан ба тест</button>
        </Form>

 
 





        


      </Container>
        
       
       </div> 
       </div>
        

     
    )
}

export default ModalTest;