 
    import React from "react";
import './mavodho.css';
import mass from './../img/mass.png';
import amn from './../img/amn.jpg';
import webjs from './../img/webjs.jpg';
import kl from './../img/kl.jpg';
import python from './../img/python.png';
import kurbonov2 from './../img/kurbonov2.jpg';
import kurb from './../img/kurb.jpg';
import rustamova from './../img/rustamova.jpg';
import biom from './../img/biom.jpg';
import delphi from './../img/delphi.jpg';
import webs from './../img/webs.jpg';
import mm from './../img/mm.jpg';
import mmhb from './../img/mmhb.png';
import st from './../img/st.jpg';
import pirov from './../img/pirov.jpg';
import jumaeva from './../img/jumaeva.jpeg';
import jumaev from './../img/jumaev.jpg';
import arabov from './../img/arabov.jpg';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
  FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
   FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
    FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
    FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
     FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers} from 'react-icons/fa';

     import Footer from '../Components/Footer';
    
     import Header from '../Components/Header';
     import Nav from '../Components/Nav';


export const Mavodho=()=>(
<div class="mav">
    
    <Header />

<section >

    
     <h1>Маводҳои таълимӣ</h1> 
<div className="mavod">
     <div className="mavod_grid">
     <div className="book">
    <div className="nomer">1</div>
<img 
            src={webjs}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Web-барномасозӣ ва асосҳои забони JavaScript
</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Комилов О.О., Рустамова Х.Р.</div>
            <div className="statusbook">Китоби дарсӣ</div>
            <div className="commentbook">Китоби дарсӣ барои донишҷӯёни мактаби олӣ, коллеҷҳо,
             литсейҳо, мактабҳои миёна ва барои навомӯзони забони барномасозии сатҳи web - JavaScript 
             тавсия шуда, барои эҷоди маҳорати навишти барнома дар 
            саҳифаи электронӣ  бо истифодаи забони JavaScript мусоидат мекунад.
</div>
            <div className="yearbook">Душанбе.ҶДММ “Хирадмандон”, 2025. – 118 саҳ. </div>
            </div>

</div>


<div className="book">
    <div className="nomer">2</div>
<img 
            src={amn}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Амнияти иттилоотӣ</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Ашўров Х.М., Комилов О.О., Олимшоев Р</div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook">Дар дастури методӣ масъалаҳои вобаста ба соҳаи амнияти иттилоот дида мешаванд. 
            Оид ба тарзи истифодаи дурусти системаҳои иттилоотӣ, барномаҳои зараррасони компютерӣ 
            ва ҳифз аз онҳо, инчунин, оид ба усулҳои ҳифзи иттилоот дар дастури методӣ маълумоти 
            муфассал дода шудааст. 
Дастури методӣ ҳамчун дастурамал аз фанҳои воситаҳои барномавию таҷҳизотии ҳифзи иттилоот, амнияти
 компютерӣ ва таъминоти барномавии бехатарии иттилоот дар ихтисосҳои информатика, амнияти компютерӣ 
 ва забоншиносӣ ва технологияи навини иттилоотӣ истифода мешавад. Инчунин, ба донишҷўён ва нафароне,
  ки оид ба амнияти иттилоот маълумоти муфассал гирифтан мехоҳанд, тавсия мегардад.
</div>
            <div className="yearbook">Душанбе “Эр-граф”, 2018. – 113 саҳ. </div>
            </div>

</div>



<div className="book">
    <div className="nomer">3</div>
<img 
            src={kl}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Маҷмӯи супоришҳо барои корҳои лабораторӣ</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Рустамова Х.Р.</div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook"> 
            Дар васоити таълимӣ зиёда аз 150 масъалаҳои гуногуни математикӣ барои барномасозӣ гирд оварда шудааст. 
            Масъалаҳои васоити таълимӣ аслан барои корҳои санҷишӣ ва лабораторӣ аз фанни забонҳои барномасозӣ тартиб дода шудаанд. 
            
            
              </div>
            <div className="yearbook">Душанбе.2025. -61саҳ. </div>
            </div>

</div>
<div className="book">
<div className="nomer">4</div>
<img 
            src={python}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Забони барномасозии Python барои навомӯзон</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Комилов О.О., Рустамова Х.Р.</div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook">Дар васоити таълимӣ маълумоти мухтасар оид ба забони барномасозии Python 3.8 оварда шуда, шаклҳои (типҳои) асосии додаҳо дар забони мазкур муфассал шарҳ дода шудаанд. Инчунин, оид ба функсияҳои математикӣ маълумот дода шуда, намунаи ҳалли масъалаҳо оварда шудааст. 
Васоити таълимӣ барои донишҷӯёни мактаби олӣ, коллеҷҳо, литсейҳо, мактабҳои миёна ва барои навомӯзони забони барномасозии Python тавсия шуда, барои эҷоди маҳорати навишти барнома дар забони барномасозии Python  мусоидат мекунад.
</div>
            <div className="yearbook">Душанбе «Эр-Граф», 2022. - 125 с.</div>
            </div>

</div>
<div className="book">
<div className="nomer">5</div>
<img 
            src={kurbonov2}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Информатика ва технологияи иттилоотӣ</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Қурбонов К. Ю., Маликов И.О., Бадалова Б. А., 
            Сангов Ҳ.Ғ., Баротов Д.А., Раҷабов З.Ф., Юсупов Ф.С. .</div>
            <div className="statusbook">Китоб</div>
            <div className="commentbook">Дар он маводҳо оид ба информатика, иттилоот ва равандҳои иттилоотӣ, ҷомеаи иттилоотӣ,
             фарҳанги иттилоотӣ, амнияти иттилоотӣ, сахтафзорҳои муосири электронӣ, нармафзорҳои компютерӣ, виросторони коркарди 
             матнҳо ва ададҳо, виросторони графиксоз ва рўнамосоз, барномаҳои корсоз (Outlook), шабакаҳои компютерӣ, технологияҳои 
             корбарӣ дар Интернет, хадамоти Интернет, тиҷорат дар Интернет, низомҳои геоиттилоотӣ, асосҳои барномарезӣ, забонҳои 
             барномасозии консолӣ, забонҳои барномасозии объектгаро, веббарномасозӣ, забонҳои сохторбандӣ (SQL, Oracle ва ғайра), 
             системаҳои идоракунии пойгоҳи додаҳо, шаклсозӣ ва тарҳрезии компютерӣ, 
            тарҳрезии риёзӣ-компютерӣ, технологияҳои худкорсозии компютерӣ ва худкорсозии равандҳои мушаххас оварда шудааст.</div>
            <div className="yearbook">Матбааи ДДОО ба номи С.Айнӣ. Душанбе. 2019 г. 298с.</div>
            </div>

</div>
<div className="book">
<div className="nomer">6</div>
<img 
            src={kurb}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Информатика и информационная технология</div>
            <div className="autorbook"><a className="muallif">Автор: </a>Курбонов К. Ю., Маликов И. А., Бадалова Б. А., 
            Сангов Х. Г., Холова М. С., Эгамов С. Х., Юсупов Ф. С., Ахмедова Г. И.</div>
            <div className="statusbook">Книга</div>
            <div className="commentbook">В нем приведены сведение о информатике, информации и информационные процессы, 
            иинформационное общество, культура и безопасностей, современые электронные аппаратные средства, программное 
            обеспечение компьютера, обработка информации в текстовых редакторов, обработка числовых данных в электроной 
            таблицей, графические и презентатционные редакторы, компьютерные сети, технологии работы в Интернете, служба 
            (сервис) Интернета, геоинформационные системы, основы программирования, консольные языки программирования,
             объектно-ориентированные языки программирования, веб программирования, 
            системы управления базами данных (СУБД), формообразование и компьютерное моделирование, и др.</div>
            <div className="yearbook">Типография ТГПУ им.С.Айни. Душанбе. 2019 г. 344с.</div>
            </div>


</div>
<div className="book">
<div className="nomer">7</div>
<img 
            src={rustamova}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Намунаи ҳалли масъалаҳо ва супоришҳо аз таҷрибаомӯзии таълиму ҳисоб </div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Рустамова Х.Р., Комилов О.О.</div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook">Васоити таълимӣ барои донишҷӯёни курси 2-юми факултети механика ва математикаи ДМТ барои таҷрибаомӯзии таълиму ҳисоб пешбинӣ гаштааст.
Дар васоити таълимӣ намунаи ҳалли масъалаҳо дар забонҳои барномасозии визуалии Visual Studio C# ва Embarcadero Delphi дида мешавад. Инчунин, дар ҳар боб барои донишҷӯён кори мустақилона дар намуди супориши иловагӣ дода шудааст. 
</div>
            <div className="yearbook">Душанбе ЭР-Граф, 2019. -70 с..</div>
            </div>

</div>
<div className="book">
<div className="nomer">8</div>
<img 
            src={biom}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Ҳифзи биометрии иттилоот</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Комилов О.О.</div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook">Васоити таълимии мазкур масъалаи ҳифзи иттилоотро дар бар гирифта, дар он яке аз роҳҳои ҳифзи иттилоот – ҳифзи биометрӣ дида баромада шудааст. Инчунин, оид ба таърихи системаи биометрӣ, шаклҳои он, тарзи истифодабарӣ, бартарият ва камбудии ин система, татбиқи он дар шароити имрӯза ва оид ба ташаккули он маълумоти пурра гирд оварда шудааст. 
Васоити таълимӣ барои донишҷӯёни мактаби олӣ, хусусан барои донишҷӯёни ихтисоси амнияти компютерӣ ва ба нафароне, ки оид ба амнияти иттилоот маълумоти муфассал гирифтан мехоҳанд, тавсия мегардад. </div>
            <div className="yearbook">Душанбе. 2023 с.</div>
            </div>

</div>

<div className="book">
<div className="nomer">9</div>
<img 
            src={webs}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Web-саҳифаҳо</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Комилов О., Ашўров Х.</div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook">Дар дастури таълимӣ оид ба интернет, мафҳумҳои интернетӣ, имкониятҳо ва
             хидматҳои асосии шабакаи INTERNET ва оид ба забони нишонагузории HTML маълумот оварда шудааст. 
             Якчанд намунаҳои сохтани Web-саҳифаҳо низ дар дастур дида мешаванд. Ин дастур ба донишҷўён ҳамчун
              дастури таълимӣ аз фанҳои информатика, забоншиносии пайкаравӣ ва системаҳои иттилоотию ҷустуҷўӣ пешбинӣ шудааст. 
            Инчунин барноманависон ё онҳое, ки рағбати омӯзиши Web-тарроҳиро доранд метавонанд аз он истифода намоянд.  </div>
            <div className="yearbook">Душанбе, 2014.– 70 с.</div>
            </div>

</div>

<div className="book">
<div className="nomer">10</div>
<img 
            src={delphi}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Истифодаи типи сатрӣ дар забони программасозии Delphi 2010</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Ашўров Х., Комилов О. </div>
            <div className="statusbook">Дастури таълимӣ-методӣ</div>
            <div className="commentbook">Дар дастури таълимӣ-методӣ масъалаҳои вобаста ба соҳаи илми забоншиносӣ ва ҳалли онҳо
             бо имкониятҳои забони программасозии Delphi 2010 дида мешаванд. Тарзи истифодаи яке аз типњои забони программасозӣ 
             типи сатрии string бо номгўи функсияҳои махсуси он оварда шудааст. Вобаста ба ин типи сатрӣ масъалаҳо бо навишти программа
              ҳал карда шудаанд. 
            Инчунин оид ба он компонентҳое, ки дар ҳалли ин масъалаҳои гузошташуда истифода шудаанд, маълумоти мухтасар низ ҷой дорад.  </div>
            <div className="yearbook">Душанбе, 2015.– 84 с.</div>
            </div>

</div>


<div className="book">
<div className="nomer">11</div>
<img 
            src={mm}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Маҷмўи масъалаҳо  аз программасозӣ</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Ашўров Х., Комилов О. </div>
            <div className="statusbook">Дастури таълимӣ</div>
            <div className="commentbook">Дар дастур зиёда аз 400 масъалаҳои гуногуни математикӣ барои программасозӣ гирд оварда шудааст. 
            Масъалаҳо аз рўи ҳаллашон гуногунсатҳ буда барои вусъат додани лаёқати программасозии хонандагон имкон медиҳанд. 
            Инчунин дастурро донишҷўён чун маводи таълимӣ дар дарсҳои амалию лаборатории фанни забонњои программасозӣ истифода бурда метавонанд.  </div>
            <div className="yearbook">Душанбе, 2013. – 69 с.</div>
            </div>

</div>

<div className="book">
<div className="nomer">12</div>
<img 
            src={mmhb}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Маҷмўи масъалаҳо ва ҳалли онҳо дар барномасозӣ</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Комилов О.О. </div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook">Дар васоити таълимӣ зиёда аз 250 масъалаҳои гуногуни математикӣ 
            барои барномасозӣ гирд оварда шудааст. Масъалаҳои васоити таълимӣ аслан барои корҳои санҷишӣ ва 
            лабораторӣ аз фанни забонҳои барномасозӣ тартиб дода шудаанд. Инчунин онро муаллимон ва 
            донишҷўён 
            чун маводи таълимӣ дар дарсҳои амалию лаборатории фанҳои воситаҳои таҷҳизотӣ - барномавӣ 
            ва техникии ҳифзи иттилоот,
             асосҳои математикии информатика ва дар таҷрибаи таълимии ҳисоб истифода бурда метавонанд. </div>
            <div className="yearbook">Душанбе, 2018. – 101 с.</div>
            </div>

</div>

<div className="book">
<div className="nomer">13</div>
<img 
            src={mass}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Массивы и вектора в языке программирования С++</div>
            <div className="autorbook"><a className="muallif">Автор: </a>Комилов О.О. </div>
            <div className="statusbook">Учебное пособие</div>
            <div className="commentbook">В учебном пособии описаны основные свойства и методы обработки массивов в программах. Коротко изложены теоретические основы работы с массивами и векторами. Приведены примеры применения динамических массивов в программах. Для освоения представленного материала, предложены решения более сто задач на языке программирования C++. 
      Учебное пособие предназначено для широкого круга читателей, изучающим программирование в учебных заведениях или самостоятельно. 
 </div>
            <div className="yearbook">Душанбе, 2019. – 152 с.</div>
            </div>

</div>

<div className="book">
<div className="nomer">14</div>
<img 
            src={st}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Саволномаҳои тестӣ аз системаҳои иттилоотӣ-ҷустуҷӯӣ ва амнияти иттилот</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Комилов О.О. </div>
            <div className="statusbook">Дастури методӣ</div>
            <div className="commentbook">Дар дастури методӣ саволҳо дар намуди тестӣ оид ба мавзӯҳои назарияи иттилоот, системаҳои иттилоотию ҷустуҷӯӣ ва амнияти иттилоотӣ оварда шудаанд.  Донишҷӯён метавонанд маълумотҳои лозимаро оид ба мавзӯҳои қайдшуда, дар шакли саволу ҷавобҳо пайдо кунанд. 
Дастури методӣ барои донишҷӯёни ихтисосҳои забоншиносӣ ва технологияи навини иттилоотӣ, хитоӣ-информатика ва амнияти компютерии муассисаҳои таҳсилоти олӣ пешбинӣ шудааст.  
 </div>
            <div className="yearbook">Душанбе, 2020. – 82 с.</div>
            </div>

</div>

<div className="book">
<div className="nomer">15</div>
<img 
            src={pirov}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Барномасозии мантиқӣ ва асосоҳои тафаккури зеҳни сунъӣ</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Пиров С.М. </div>
            <div className="statusbook">Дастури таълимӣ</div>
            <div className="commentbook"> 
Аз дастури таълимӣ хонанда метавонад оид ба асосҳои тафаккури сунъӣ ва забони барномасозии мантиқӣ маълумоти зарурӣ пайдо намояд. Дастур барои донишҷӯёни ихтисоси информатика ва амнияти компютерии муассисаи таҳсилоти олӣ пешбинӣ шудааст.  
 </div>
            <div className="yearbook">Матбааи ДМТ. Душанбе, 2017. – 88 с.</div>
            </div>

</div>


<div className="book">
<div className="nomer">16</div>
<img 
            src={jumaeva}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Асосҳои барномасозӣ дар забони Паскал</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Ҷумаев Э.Ҳ., Ҷумаева Н.Э. </div>
            <div className="statusbook">Дастури таълимӣ</div>
            <div className="commentbook">  
Дастури методӣ барои донишҷӯёни ихтисосҳои забоншиносӣ ва технологияи навини иттилоотӣ, хитоӣ-информатика ва амнияти компютерии муассисаҳои таҳсилоти олӣ пешбинӣ шудааст.  
 </div>
            <div className="yearbook">Душанбе, 2022. – 200 с.</div>
            </div>

</div>

<div className="book">
<div className="nomer">17</div>
<img 
            src={jumaev}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Асосҳои информатика ва программасозӣ дар забони Паскал</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>Ҷумаев Э.Ҳ., Ҷумаева Н.Э. </div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook"> 
 </div>
            <div className="yearbook">Душанбе, 2002. – 70 с.</div>
            </div>

</div>


<div className="book">
<div className="nomer">18</div>
<img 
            src={arabov}
           className="imgbook"
            />
            <div className="aboutbook">
            <div className="titlebook">Маҷмӯи мисол ва масъалаҳо аз барномасозӣ</div>
            <div className="autorbook"><a className="muallif">Муаллиф: </a>М.Арабов, М.Халилова, Ф.Пиров </div>
            <div className="statusbook">Васоити таълимӣ</div>
            <div className="commentbook">  
 </div>
            <div className="yearbook">Душанбе, 2002. – 70 с.</div>
            </div>

</div>



     </div>
                            </div>  






          
 
 
        
  
 
         
          
          </section>
<Footer />
          </div>





)
 