 
    import React from "react";
    import './test.css';
    import mass from './../img/mass.png';
    import amn from './../img/amn.jpg';
    import webjs from './../img/webjs.jpg';
    import kl from './../img/kl.jpg';
    import python from './../img/python.png';
    import kurbonov2 from './../img/kurbonov2.jpg';
    import kurb from './../img/kurb.jpg';
    import rustamova from './../img/rustamova.jpg';
    import biom from './../img/biom.jpg';
    import delphi from './../img/delphi.jpg';
    import webs from './../img/webs.jpg';
    import mm from './../img/mm.jpg';
    import mmhb from './../img/mmhb.png';
    import st from './../img/st.jpg';
    import pirov from './../img/pirov.jpg';
    import jumaeva from './../img/jumaeva.jpeg';
    import jumaev from './../img/jumaev.jpg';
    import arabov from './../img/arabov.jpg';
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
      FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
       FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
        FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
        FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
         FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers} from 'react-icons/fa';
    
         import Footer from '../Components/Footer';
        
         import HeaderTest from '../Components/HeaderTest';
         import Nav from '../Components/Nav';
    
    
    export const Test=()=>(
    <div class="test_section">
        
        <HeaderTest />
    
    <section >   
        
<div className='aboutTest'>

<div className="aboutRight">
<p><a className="pa">Тести онлайн </a> барои талабагон ва донишҷӯён пешбинӣ шудааст. 
Тест барои санҷиши дониши хонандагон ва ҳам барои муаллимон барои назорати дониши 
хонандагон мувофиқ аст. Санҷишҳои тестӣ бо ҷавобҳо аз рӯи синфҳо ва фанҳо гурӯҳбандӣ шуда, дар шакли 
тести онлайн гузаронида мешаванд, ки аз рӯи натиҷаашон баҳо гузошта мешавад.
 Дар хазинаи додаҳо тестҳо бо забонҳои русӣ ва тоҷикӣ мавҷуданд.
</p>
</div>  
<div className="aboutLeft"></div>
</div> 
<div className='aboutTests'>
<div className="aboutLefts">
<ul className="fanho">
    <li><b>01.</b> Забони барномасозии Python </li>
    <li><b>02.</b> Забони барномасозии C++ </li>
    <li><b>03.</b> Web-барномасозӣ </li>
    <li><b>04.</b> Асосҳои назарияи ҷустуҷӯи иттилоот</li>
    <li><b>05.</b> Таъминоти барномавии бехатарии иттилоот</li> 
    </ul>
</div>
<div className="aboutRights">
<ul className="fanho">
    <li><b>01.</b> Забони барномасозии Python </li>
    <li><b>02.</b> Забони барномасозии C++ </li>
    <li><b>03.</b> Web-барномасозӣ </li>
    <li><b>04.</b> Асосҳои назарияи ҷустуҷӯи иттилоот</li>
    <li><b>05.</b> Таъминоти барномавии бехатарии иттилоот</li>
    </ul>
    </div>  
</div>             
              </section>
              <Footer />
              </div>
    
    
    
    
    
    )
     