import React from 'react';
import './Modal.css';
function Modal({ closeModal }){
    return(
       <div className='modalBackground'> 
       <div className='modalContainer'> 
       <div className='titleCloseBtnModal'>
       <button  onClick={() => closeModal(false)}>×</button>
       </div><p>Руйхати корҳои курсӣ 2024-2025</p> 
      <div className='jdkk'> 
<table>
<tr className='ttd'>
    <td > </td>
    <td  ></td>
    <td  >Ихтисоси 98010101 курси 2 </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Ҷураев Беҳруз</td>
    <td>Коркард ва тадбиқи барнома дар системаи оператсионии Linux</td>
    <td>проф. Нуров И.Ҷ.		</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Акрамов Ф.</td>
    <td>Мафҳуми биометрия ҳамчун илм</td>
    <td>дотc. Комилов О.О.</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Бобохонов Т.</td>
    <td>Стеганографияи компютерӣ</td>
    <td>дотс.</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Кобилов М.</td>
    <td>Мафҳуми киберҳамлаҳо дар интернет</td>
    <td>дотс. Комилов О.О.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>	Давлатов З.</td>
    <td>Операторҳои асосии забони С++</td>
    <td>	м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>6</td>
    <td> Лашкаров А.</td>
    <td>Функсия (зербарнома) дар забони С++	м.к.</td>
    <td>м.к.Шарифзода З.И.	</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Мадудов У.</td>
    <td>Усулҳои асосии ҳифзи иттилоот		</td>
    <td>м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Маҳмудов И.</td>
    <td>  Таҳияи лоиҳаи барномаи кодгузории символҳо бо Unicode		</td>
    <td>дотс. Ашӯров Х.М.	</td>
  </tr>

  <tr>
    <td>9</td>
    <td>Маҷидзода А.</td>
    <td>  Системаи ҳифзи иттилоот аз дастёбии беиҷозат дар МЭҲ 		</td>
    <td>дотс. Ашӯров Х.М.	</td>
  </tr>
  <tr>
    <td>10</td>
    <td>		Нишонов А.</td>
    <td>  Операторҳои идоракунӣ дар забони С++	</td>
    <td>м.к. Шарифзода З.И.	</td>
  </tr>
  <tr>
    <td>11</td>
    <td> 	Салимов С. </td>
    <td> Операторҳои даврии забони С++	</td>
    <td>м.к. Шарифзода З.И.	</td>
  </tr>
  <tr>
    <td>12</td>
    <td> Раҷабзода У. </td>
    <td>  Массив ва истифодаи он дар забони С++	</td>
    <td>м.к. Шарифзода З.И.	</td>
  </tr>
 
  <tr>
    <td>13</td>
    <td> Муродиллозода С. </td>
    <td>  Протоколҳои криптографӣ дар ҳифзу коркарди ахбор</td>
    <td>проф. Нуров И.Ҷ.	</td>
  </tr>
  <tr>
    <td>14</td>
    <td>  Сафаров Ш. </td>
    <td> Истифодаи технологияҳои иттилоотӣ ва иртиботӣ барои ҳалли масъалаи таъмини захира	  </td>
    <td>  дотс. Қурбонов К.Ю.	</td>
  </tr>


  <tr>
    <td>15</td>
    <td>  Саъдулозода Ҳ. </td>
    <td>   Операторҳои даврии забони Паскал  </td>
    <td>  	дотс. Ҷумаев Э.		</td>
  </tr>


  <tr>
    <td>16</td>
    <td>  Тошева С. </td>
    <td> Кор бо сатрҳо дар забони С++  </td>
    <td>  дотс. Ҷумаев Э.			</td>
  </tr>
 
  <tr>
    <td>17</td>
    <td> Ҳайдаров Ҳ. </td>
    <td>Массив ва истифодаи он дар забони С++	   </td>
    <td> асс.Шодибекова М.	   		</td>
  </tr>

  <tr>
    <td>18</td>
    <td> Шабонова Ж.</td>
    <td>  Барномасозии маҷмӯҳои гуногун дар С++	   </td>
    <td> м.к.Пиров С.М.	   		</td>
  </tr>
  <tr>
    <td>19</td>
    <td> Додохонов Ҳ.</td>
    <td>  Сохтани графики функсияҳо дар Python	 </td>
    <td> м.к.Пиров С.М.	   		</td>
  </tr>
 
  <tr>
    <td>20</td>
    <td> Раҳматов С.</td>
    <td>    Функсияҳои кор бо векторҳо дар С++	 </td>
    <td> м.к.Пиров С.М.	   		</td>
  </tr>
 
  <tr>
    <td>21</td>
    <td> Назаров А.</td>
    <td>   Барномасозии графики функсияҳо дар С++ </td>
    <td>  	асс. Қурбонова Н.	  		</td>
  </tr>
  
  <tr>
    <td>22</td>
    <td> 	Джаборов Ш.</td>
    <td>    Сохтори додашудаҳо дар забони С++	</td>
    <td>  	дотс.Рустамова Х.Р. 		</td>
  </tr>

  <tr>
    <td>23</td>
    <td> 	Шамсов М.</td>
    <td>   Ҳифз дар системаи омилии Windows 10	</td>
    <td>  	дотс.Рустамова Х.Р. 		</td>
  </tr>

 
  <tr>
    <td>24</td>
    <td> 	Холов Б.</td>
    <td>   Воситаҳои дохилкуниву хориҷкунӣ дар забони С++	</td>
    <td>  	дотс.Рустамова Х.Р. 		</td>
  </tr>

  <tr>
    <td>25</td>
    <td> 	Жумаев С.</td>
    <td>  Таҳияи барномаи ҳифзи маълумот бо истифода аз шифргузории оддӣ	</td>
    <td>  	асс.Мусоев С. 		</td>
  </tr>




  <tr className='ttd'>
    <td > </td>
    <td  >Ихтисоси 21050103 курси 3</td>
    <td  > </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>

</table>

      </div>
     
       
       </div> 
       </div>
        

     
    )
}

export default Modal;